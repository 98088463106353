<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CASH VOUCHER</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12>
          <v-form ref="form" class="multi-col-validation">
            <v-layout row wrap class="mx-1 mb-5" v-if="!has_existing_voucher">
              <v-flex xs12 md12>
                <v-select
                  class="mx-2"
                  v-model="category_id1"
                  dense
                  outlined
                  label="Category"
                  :items="category_items1"
                  item-value="id"
                  item-text="category"
                  :rules="rules.combobox_rule"
                  @change="selected_bank_category"
                ></v-select>
              </v-flex>
              <v-flex xs12 md3>
                <v-select
                  v-model="bank_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Bank"
                  :items="bank_items"
                  item-value="id"
                  item-text="bank_code"
                  @change="selected_bank"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  v-if="!bal_loading"
                  v-model="remaining_balance"
                  class="mx-2"
                  dense
                  outlined
                  label="Remaining Balance"
                  readonly
                ></v-text-field>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-flex>
              <v-flex xs12 md6>
                <v-checkbox
                  v-model="is_auto_debit"
                  label="Auto Debit Transaction?"
                  dense
                  outlined
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 md6>
                <v-select
                  v-if="!is_auto_debit"
                  v-model="cheque_no"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque"
                  :items="cheque_items"
                  item-value="cheque_no"
                  item-text="cheque_no"
                  :rules="rules.combobox_rule"
                  readonly
                ></v-select>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="cheque_date"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque Date"
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="payee"
                  class="mx-2"
                  dense
                  outlined
                  label="Payee"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="allocation_of_fund"
                  class="mx-2"
                  dense
                  outlined
                  label="Allocation of Fund"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  class="w-full"
                  color="primary"
                  @click="save_voucher"
                  v-if="!saving_data"
                >
                  Create Voucher
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-layout>
            <v-layout row wrap class="mx-1 mb-5" v-else>
              <v-flex xs12 md12>
                <v-text-field
                  outlined
                  v-model="existing_voucher.voucher_no"
                  class="mx-2"
                  dense
                  label="Voucher #"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="existing_voucher.bank.bank_code"
                  class="mx-2"
                  dense
                  outlined
                  label="Bank Code"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-checkbox
                  v-model="existing_voucher.cheque_no===0"
                  label="Auto Debit Transaction?"
                  dense
                  outlined
                  disabled
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 md6 v-if="!is_auto_debit">
                <v-text-field
                  v-model="existing_voucher.cheque_no"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque #"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="!is_auto_debit">
                <v-text-field
                  v-model="existing_voucher.cheque_date"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque Date"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="existing_voucher.payee"
                  class="mx-2"
                  dense
                  outlined
                  label="Payee"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="existing_voucher.allocation_of_fund"
                  class="mx-2"
                  dense
                  outlined
                  label="Allocation of Fund"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12" v-if="existing_voucher.request_data.length===0">
                <v-btn
                  class="w-full"
                  color="error"
                  @click="delete_voucher"
                  v-if="!saving_data"
                >
                  Delete Voucher
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="error"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
              <v-col cols="12" md="12" v-else>
                <v-btn
                  class="w-full"
                  color="info"
                  @click="approve_voucher"
                  v-if="!saving_data"
                >
                  Approve Voucher
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="info"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-layout>
          </v-form>
        </v-flex>
        <v-layout col>
          <v-flex xs12 md3>
            <v-form ref="form2" class="multi-col-validation" v-if="has_existing_voucher">
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">Request Data</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-select
                v-model="category_id"
                class="mx-2  mt-2"
                dense
                outlined
                label="Category"
                :items="category_items"
                item-value="id"
                item-text="category"
                @change="selected_category"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-select
                v-model="request_id"
                class="mx-2"
                dense
                outlined
                label="Request ID"
                :items="request_items"
                item-value="id"
                item-text="id"
                @change="selected_request"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-col cols="12" v-show="alert_request">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"
                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message_request }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  class="w-full"
                  color="primary"
                  @click="save_request"
                  v-if="!saving_data_request"
                >
                  Save Request
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-form>
            <v-data-table dense
                          :headers="headers2"
                          :items="vouchered_items"
                          disable-pagination
                          hide-default-footer
                          height="530"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.acro + '-' + item.year + '-' + item.voucher_no }}
                  </td>
                  <td>
                    {{ item.bank.bank_code }}
                  </td>
                  <td>
                    {{ item.cheque_no }}
                  </td>
                  <td>
                    {{ item.payee }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex xs12 md9 class="mx-2" v-if="data_items.length>0">
            <h2>Request Data | Total: P {{ total_amount }}</h2>
            <v-data-table dense
                          :headers="headers"
                          :items="data_items"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.request_id }}
                  </td>
                  <td>
                    {{ item.category.category }}
                  </td>
                  <td>
                    {{ item.branch != null ? item.branch.branch_code : '' }}
                  </td>
                  <td>
                    {{ item.particulars.particulars }}
                  </td>
                  <td>
                    {{ item.description }}
                  </td>
                  <td>
                    {{ item.credit_to }}
                  </td>
                  <td>
                    {{ item.payment_for }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
import {mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline} from "@mdi/js";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import category from "@/store/module/data/category";

const initialState = () => {
  return {
    saving_data: false,
    alert: false,
    alert_message: '',
    saving_data_request: false,
    alert_request: false,
    alert_message_request: '',
    total_amount: 0,
    headers: [
      {text: 'ID', value: 'date_of_deposit', sortable: false},
      {text: 'Request ID', value: 'tin', sortable: false},
      {text: 'Category', value: 'last_name', sortable: false},
      {text: 'Branch', value: 'last_name', sortable: false},
      {text: 'Particulars', value: 'last_name', sortable: false},
      {text: 'Description', value: 'last_name', sortable: false},
      {text: 'Credit To', value: 'last_name', sortable: false},
      {text: 'Payment For', value: 'last_name', sortable: false},
      {text: 'Amount', value: 'last_name', sortable: false},
    ],
    headers2: [
      {text: 'Voucher#', value: 'date_of_deposit', sortable: false},
      {text: 'Bank', value: 'last_name', sortable: false},
      {text: 'Cheque#', value: 'last_name', sortable: false},
      {text: 'Payee', value: 'tin', sortable: false},
    ],
    data_items_original: [],
    data_items: [],
    request_id: '',
    request_items: [],
    category_id: '',
    category_items: [],
    allocation_of_fund: '',
    payee: '',
    is_auto_debit: false,
    cheque_no: '',
    cheque_date: '',
    remaining_balance: '',
    voucher_no: '',
    bank_id: '',
    bank_items: [],
    cheque_items: [],
    vouchered_items: [],
    category_id1: '',
    category_items1: [],

    has_existing_voucher: false,
    existing_voucher: {},
    bal_loading: false,
  }
}
export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiAccountCashOutline,
        mdiInformationOutline,
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.initialize_data()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['employee_id', 'month_of']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('cash_vouchers', ['initialize_bank_cash_voucher', 'initialize_cash_voucher', 'save_request_data_cash_voucher', 'approved_cash_voucher', 'register_cash_voucher', 'delete_cash_voucher']),
    ...mapActions('category', ['list_of_available_for_cash_voucher']),
    ...mapActions('bank_transaction', ['trial_and_balance_data_bank']),
    ...mapActions('transaction_months', ['current_month']),
    ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
    selected_bank_category() {
      this.initialize_bank_cash_voucher({
        category_id: this.category_id1,
        employee_id:this.employee_id,
      })
        .then(response => {
          this.bank_items = response.data
          this.category_id = this.category_id1
        })
        .catch(error => {
          console.log(error)
        })
    },
    async initialize_data() {
      this.initialize_salaries_employee()
        .then(response => {
          this.category_items1 = response.data[0].category
          this.category_items1.splice(0, 1)
        })
        .catch(error => {
          console.log(error)
        })
      this.category_id = ''
      this.request_id = ''
      this.request_items = []
      await this.initialize_cash_voucher({
        created_id: this.employee_id,
      })
        .then(response => {
          this.vouchered_items = response.data[0].approved_vouchers
          this.existing_voucher = response.data[0].existing
          // this.bank_items = response.data[0].bank_available
          this.has_existing_voucher = this.existing_voucher != null
          if (this.has_existing_voucher) {
            this.data_items_original = this.existing_voucher.request_data
            this.data_items = this.data_items_original
            var tto = 0;
            this.data_items.forEach(function (item) {
              tto += parseFloat(item.amount)
            });
            this.total_amount = this.formatPrice(tto)
            this.category_id1 = this.existing_voucher.bank.category_id
            this.category_id = this.existing_voucher.bank.category_id
          }
        })
        .catch(error => {
          console.log(error)
        })
      await this.list_of_available_for_cash_voucher()
        .then(response => {
          this.category_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
      await this.selected_category(this.category_id)
    },
    date_format(value) {
      return moment(value)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    selected_request(value) {
      var index = this.request_items.map(function (x) {
        return x.id;
      }).indexOf(value)
      if (index != -1) {
        var datas = []
        var tto = 0;
        this.request_items[index].request_data.forEach(function (item) {
          tto += parseFloat(item.amount)
          datas.push({
            id: item.id,
            request_id: item.request_id,
            category: item.category,
            branch: item.branch,
            particulars: item.particulars,
            description: item.description,
            credit_to: item.credit_to,
            payment_for: item.payment_for,
            amount: item.amount
          })
        });
        this.data_items_original.forEach(function (item) {
          tto += parseFloat(item.amount)
          datas.push({
            id: item.id,
            request_id: item.request_id,
            category: item.category,
            branch: item.branch,
            particulars: item.particulars,
            description: item.description,
            credit_to: item.credit_to,
            payment_for: item.payment_for,
            amount: item.amount
          })
        });
        this.total_amount = this.formatPrice(tto)
        this.data_items = datas
      }
    },
    selected_category(value) {
      this.request_items=[]
      // var proceed = false;
      // if (this.category_id1 === 4) {
      //   if ((value === 4 || value === 2)) {
      //     proceed = true
      //   }
      // } else if (this.category_id1 === value) {
      //   proceed = true
      // }
      // if (proceed) {
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.request_items = this.category_items[index].request
          var datas = []
          var tto = 0
          this.data_items_original.forEach(function (item) {
            tto += parseFloat(item.amount)
            datas.push({
              id: item.id,
              request_id: item.request_id,
              category: item.category,
              branch: item.branch,
              particulars: item.particulars,
              description: item.description,
              credit_to: item.credit_to,
              payment_for: item.payment_for,
              amount: item.amount
            })
          });
          this.data_items = datas
          this.total_amount = this.formatPrice(tto)
        }
      // }
    },
    delete_voucher() {
      this.saving_data = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('id', this.existing_voucher.id);
        this.delete_cash_voucher(data)
          .then(() => {
            this.saving_data = false
            Object.assign(this.$data, initialState())
            this.initialize_data()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving_data = false
      }
    },
    save_voucher() {
      this.saving_data = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('category_id', this.category_id);
        data.append('bank_id', this.bank_id);
        data.append('cheque_date', this.cheque_date);
        data.append('payee', this.payee.toUpperCase());
        data.append('allocation_of_fund', this.allocation_of_fund.toUpperCase());
        data.append('created_id', this.employee_id);
        data.append('cheque_no', this.cheque_no);
        if (this.is_auto_debit) {
          data.append('cheque_no', 0);
        }

        this.register_cash_voucher(data)
          .then(response => {
            var color = 'error'
            if (response.status === 200) {
              var color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_data = false
            Object.assign(this.$data, initialState())
            this.initialize_data()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving_data = false
      }
    },
    async selected_bank(value) {
      this.bal_loading = true
      this.cheque_no = ''
      var index = await this.bank_items.map(function (x) {
        return x.id;
      }).indexOf(value)
      if (index != -1) {
        this.cheque_items = await this.bank_items[index].cheque_book_series
        if (this.cheque_items.length > 0) {
          this.cheque_no = await this.cheque_items[0].cheque_no
        }
        const data = new FormData()
        await this.current_month()
          .then(response => {
            data.append('month_of_id', response.data.id);
            data.append('bank_id', value);
            data.append('is_cash_flow', 1);
            data.append('category_id', -1);
            data.append('view_for_deposit', 0);
            data.append('from_date', moment().subtract(6, 'months').format('YYYY-MM-DD'));
            data.append('to_date', moment().format('YYYY-MM-DD'));

            this.trial_and_balance_data_bank(data)
              .then(response2 => {
                this.remaining_balance = this.formatPrice(response2.data[0].total)
                this.bal_loading = false
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    save_request() {
      this.saving_data_request = true
      this.alert_request = false
      if (this.$refs.form2.validate()) {
        this.save_request_data_cash_voucher({
          request_id: this.request_id,
          cv_id: this.existing_voucher.id
        })
          .then(() => {
            this.saving_data_request = false
            this.change_snackbar({
              show: true,
              color: 'success',
              text: 'SUCCESSFULLY ADDED REQUEST',
            })
            this.initialize_data()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.alert_request = true
        this.alert_message_request = 'Please Fill up the field/s'
        this.saving_data_request = false
      }
    },
    approve_voucher() {
      this.approved_cash_voucher({
        id: this.existing_voucher.id
      })
        .then(() => {
          this.change_snackbar({
            show: true,
            color: 'success',
            text: 'SUCCESSFULL APPROVED VOUCHER',
          })
          Object.assign(this.$data, initialState())
          this.initialize_data()
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
